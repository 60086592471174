<template>
  <div>
    <AppLoader
      v-if="loading"
      modal
    />
    <main class="u-container">
      <header>
        <h1 class="font-bold text-2xl">
          View, Edit or Cancel Your Change-of-Address Order
        </h1>
        <p class="mt-3">
          Certain elements of your Change-of-Address may not be editable on this system. If you need to change those elements, you will need to cancel this Change-of-Address and submit a new Change-of-Address with the correct information.
        </p>
        <router-link
          :to="{ name: 'cancel' }"
          class="c-btn w-full mt-3"
        >
          Cancel My Order
        </router-link>
      </header>
      <div class="c-hairline mt-6" />
      <form
        ref="form"
        novalidate
        @submit.prevent="validate"
      >
        <!-- Move Information Section -->
        <section class="my-6">
          <div class="flex">
            <h2 class="font-bold text-xl w-1/2">
              Move Information
            </h2>
            <Tooltip class="w-1/2">
              <template slot="tip">
                <p class="font-bold">
                  More Information:
                </p>
                <p class="mt-3">
                  If you need to modify elements of your order that are not allowed, you will need to cancel your Change-of-Address Order and file a new one.
                </p>
                <p class="mt-3">
                  You are able to make some of the following updates:
                </p>
                <ul class="mt-3">
                  <li>
                    Change your email address and receive reminders of mail forwarding expiration dates
                  </li>
                  <li>
                    Change your phone number
                  </li>
                  <!-- if hydrated coa is family filer -->
                  <li v-if="moverType === 'FAMILY'">
                    Switch from a family move to an individual move
                  </li>
                  <!-- if hydrated coa is temp -->
                  <li v-if="forwardType === 'TEMPORARY'">
                    Switch from a temporary move to a permanent move
                  </li>
                  <!-- if start date can be changed -->
                  <li v-if="startDateEditable">
                    Change your mail forwarding start date
                  </li>
                  <!-- if hydrated coa is temp and end date can be changed -->
                  <li v-if="forwardType === 'TEMPORARY' && endDateEditable">
                    Change your mail forwarding end date
                  </li>
                  <li>
                    Cancel your Change-of-Address order altogether
                  </li>
                  <li>
                    If the change you want to make isn't available, you'll be able to cancel the original request and file a new Change-of-Address after you log in.
                  </li>
                </ul>
              </template>
              <template slot="accessible">
                How to find your Confirmation Code description
              </template>
            </Tooltip>
          </div>
          <div class="mt-3">
            <label class="text-gray-500 text-sm">Processed On</label>
            <p class="text-blue-200">
              {{ formatProcessedOnDate }}
            </p>
          </div>
          <div class="mt-3">
            <label class="text-gray-500 text-sm">
              {{ moverType === 'BUSINESS' ? 'Business Name' : 'Name' }}
            </label>
            <p class="text-blue-200 uppercase">
              {{ moverType === 'BUSINESS' ? businessName : fullName }}
            </p>
          </div>
          <!-- Only show if moverType is FAMILY -->
          <!-- https://imagitas.atlassian.net/wiki/spaces/MGODOC/pages/1197604889/COAC+Edit+Mode+Rules+and+Functions -->
          <InputDropdown
            v-if="allowMoverTypeEdit"
            ref="moverType"
            v-model="moverType"
            label="Who's Moving"
            name="mover-type"
            class="mt-3"
            :has-error="errors.moverType.hasError"
          >
            <option value="INDIVIDUAL">
              Individual
            </option>
            <option value="FAMILY">
              FAMILY
            </option>
          </InputDropdown>
          <div
            v-else
            ref="moverTypeReadOnly"
            class="mt-3"
          >
            <label class="text-gray-500 text-sm">Who's Moving</label>
            <p class="text-blue-200 uppercase">
              {{ moverType }}
            </p>
          </div>
          <InputText
            v-model.trim="email"
            label="Email Address"
            placeholder="Hello@mail.com"
            name="email"
            class="mt-3"
            :has-error="errors.email.hasError"
          >
            <template slot="helper">
              {{ errors.email.message }}
            </template>
          </InputText>
          <InputPhone
            v-model="phoneNumber"
            label="Phone Number"
            placeholder="Phone Number"
            name="phone"
            class="mt-3"
            :has-error="errors.phoneNumber.hasError"
          >
            <template
              v-if="errors.phoneNumber.hasError"
              slot="helper"
            >
              {{ errors.phoneNumber.message }}
            </template>
          </InputPhone>
          <InputDropdown
            v-model="phoneType"
            label="Phone Type"
            name="phone-type"
            class="mt-3"
            :has-error="errors.phoneType.hasError"
          >
            <option
              value="MOBILE"
            >
              Mobile
            </option>
            <option value="HOME">
              Home
            </option>
            <span
              v-if="errors.phoneType.hasError"
              slot="helper"
            >
              {{ errors.phoneType.message }}
            </span>
          </InputDropdown>
          <!-- Only show if forwardType is TEMPORARY -->
          <!-- https://imagitas.atlassian.net/wiki/spaces/MGODOC/pages/1197604889/COAC+Edit+Mode+Rules+and+Functions -->
          <InputDropdown
            v-if="allowForwardTypeEdit"
            ref="forwardType"
            v-model="forwardType"
            label="Type of Move"
            name="forward-type"
            class="mt-3"
            :has-error="errors.forwardType.hasError"
          >
            <option
              value="PERMANENT"
            >
              Permanent
            </option>
            <option
              value="TEMPORARY"
            >
              Temporary
            </option>
            <span
              v-if="errors.forwardType.hasError"
              slot="helper"
            >
              {{ errors.forwardType.message }}
            </span>
          </InputDropdown>
          <div
            v-else
            ref="forwardTypeReadOnly"
            class="mt-3"
          >
            <label class="text-gray-500 text-sm">Type of Move</label>
            <p class="text-blue-200 uppercase">
              {{ forwardType }}
            </p>
          </div>
          <InputDatePicker
            v-if="startDateEditable"
            ref="startDate"
            v-model="startDate"
            label="Start Forwarding"
            :min="minStartDate"
            :max="maxStartDate"
            name="forward-start-date"
            class="mt-3 text-left"
          >
            <template slot="helper">
              {{ errors.startDate.message }}
            </template>
          </InputDatePicker>
          <div v-else-if="formattedStartDate">
            <label class="text-gray-500 text-sm">
              {{
                forwardType === 'TEMPORARY'
                  ? 'Requested Start Forwarding Date'
                  : 'Requested Mail Forwarding Date'
              }}
            </label>
            <p class="text-blue-200 uppercase">
              {{ formattedStartDate }}
            </p>
          </div>
          <InputDatePicker
            v-if="forwardType === 'TEMPORARY' && endDateEditable"
            ref="endDate"
            v-model="endDate"
            label="End Forwarding"
            :min="minEndDate"
            :max="maxEndDate"
            name="forward-end-date"
            class="mt-3 text-left"
            :has-error="errors.endDate.hasError"
          >
            <template slot="helper">
              {{ errors.endDate.message }}
            </template>
          </InputDatePicker>
          <div
            v-else-if="forwardType === 'TEMPORARY' && formattedEndDate"
            ref="endDateReadOnly"
            class="mt-3"
          >
            <label class="text-gray-500 text-sm">Requested End Forwarding Date</label>
            <p class="text-blue-200 uppercase">
              {{ formattedEndDate }}
            </p>
          </div>
        </section>
        <div class="c-hairline mt-6" />
        <!-- Old Address -->
        <section class="my-6">
          <h3 class="font-bold text-xl">
            Old Address
          </h3>
          <InputText
            v-model="oldStreetAddress"
            label="Street Address"
            placeholder="Street Address"
            name="old-street-address"
            class="mt-3"
            :has-error="errors.oldStreetAddress.hasError"
            @input="oldPoBoxNumber = ''"
          >
            <template slot="helper">
              {{ errors.oldStreetAddress.message }}
            </template>
          </InputText>
          <InputText
            v-if="oldState === 'PR'"
            v-model="oldUrbanizationName"
            label="Urbanization Name (Puerto Rico only)"
            name="old-urbanization-name"
            placeholder="URB HIGHLAND GDNS"
            class="mt-3"
            :has-error="errors.oldUrbanizationName.hasError"
          >
            <template slot="helper">
              {{ errors.oldUrbanizationName.message }}
            </template>
          </InputText>
          <InputText
            v-if="showOldPoBox"
            v-model="oldPoBoxNumber"
            label="PO Box"
            name="old-po-box"
            placeholder="PO Box 123"
            class="mt-3"
            :has-error="errors.oldPoBoxNumber.hasError"
          >
            <template slot="helper">
              {{ errors.oldPoBoxNumber.message }}
            </template>
          </InputText>
          <InputText
            v-model="oldCity"
            label="City"
            placeholder="City"
            name="old-city"
            class="mt-3"
            :has-error="errors.oldCity.hasError"
          >
            <template slot="helper">
              {{ errors.oldCity.message }}
            </template>
          </InputText>
          <InputState
            v-model="oldState"
            label="State"
            name="old-state"
            class="mt-3"
            :has-error="errors.oldState.hasError"
          >
            <template slot="helper">
              {{ errors.oldState.message }}
            </template>
          </InputState>
          <InputText
            ref="oldZipCode"
            v-model="oldZipCode"
            label="ZIP Code™"
            placeholder="00000"
            name="old-zip-code"
            class="mt-3"
            :disabled="billingAddressType === 'OLD'"
            :has-error="errors.oldZipCode.hasError"
          >
            <template slot="helper">
              {{ errors.oldZipCode.message }}
            </template>
          </InputText>
        </section>
        <div class="c-hairline mt-6" />
        <!-- New Address -->
        <section class="my-6 mt-6">
          <h3 class="font-bold text-xl">
            New Address
          </h3>
          <InputText
            v-model="newStreetAddress"
            label="Street Address"
            placeholder="Street Address"
            name="new-street-address"
            class="mt-3"
            :has-error="errors.newStreetAddress.hasError"
            @input="newPoBoxNumber = ''"
          >
            <template slot="helper">
              {{ errors.newStreetAddress.message }}
            </template>
          </InputText>
          <InputText
            v-if="newState === 'PR'"
            ref="newUrbanizationName"
            v-model="newUrbanizationName"
            label="Urbanization Name (Puerto Rico only)"
            name="new-urbanization-name"
            placeholder="URB HIGHLAND GDNS"
            class="mt-3"
            :has-error="errors.newUrbanizationName.hasError"
          >
            <template slot="helper">
              {{ errors.newUrbanizationName.message }}
            </template>
          </InputText>
          <InputText
            v-if="showNewPrivateMailbox"
            ref="newPrivateMailbox"
            v-model="newPrivateMailbox"
            label="Private Mailbox"
            name="new-private-mailbox"
            placeholder="PMB 123"
            class="mt-3"
            :has-error="errors.newPrivateMailbox.hasError"
          >
            <template slot="helper">
              {{ errors.newPrivateMailbox.message }}
            </template>
          </InputText>
          <InputText
            v-if="showNewPoBox"
            ref="newPoBox"
            v-model="newPoBoxNumber"
            label="PO Box"
            name="new-po-box"
            placeholder="PO Box 123"
            class="mt-3"
            :has-error="errors.newPoBoxNumber.hasError"
          >
            <template slot="helper">
              {{ errors.newPoBoxNumber.message }}
            </template>
          </InputText>
          <InputText
            ref="newCity"
            v-model="newCity"
            label="City"
            placeholder="City"
            name="new-city"
            class="mt-3"
            :has-error="errors.newCity.hasError"
          >
            <template slot="helper">
              {{ errors.newCity.message }}
            </template>
          </InputText>
          <InputState
            ref="newState"
            v-model="newState"
            label="State"
            name="new-state"
            class="mt-3"
            :has-error="errors.newState.hasError"
          >
            <template slot="helper">
              {{ errors.newState.message }}
            </template>
          </InputState>
          <InputText
            ref="newZipCode"
            v-model="newZipCode"
            :disabled="billingAddressType === 'NEW'"
            label="ZIP Code™"
            placeholder="00000"
            name="new-zip-code"
            class="mt-3"
            :has-error="errors.newZipCode.hasError"
          >
            <template slot="helper">
              {{ errors.newZipCode.message }}
            </template>
          </InputText>
        </section>
        <div class="c-hairline mt-6" />
        <section
          v-if="informedDeliveryEligible"
          class="my-6"
        >
          <h3 class="font-bold">
            Informed Delivery®
          </h3>
          <p class="mt-3">
            Make sure all your mail is moving with you! Sign up for this free service to digitally preview incoming mail and track and manage package delivery to your new address. If you’re already using Informed Delivery®,  the service will automatically carry over to your new address. Please do not check the box below.
          </p>
          <InputCheckbox
            v-model="informedDelivery"
            name="informed-delivery"
            class="mt-3"
          >
            I opt in to Informed Delivery<sup>®</sup> and have read, understand, and agree to the <a
              id="informed-delivery-terms"
              href="https://reg.usps.com/entreg/assets/html/informed-delivery.html"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link"
              @click.stop
            >Terms and Conditions</a>.
          </InputCheckbox>
        </section>
        <section class="my-6">
          <p class="text-sm">
            <span class="font-bold">Why your name and address might look different than how you entered them:</span>
            <br>special characters in your name (like apostrophes) may have been removed and your address(es) may have been standardized to match the official U.S. Postal Service format. This helps forward your mail more effectively.
          </p>
          <button
            id="save-changes"
            class="c-btn w-full mt-6"
            type="submit"
          >
            Save Changes
          </button>
          <router-link
            :to="{ name: 'review', query: { editCanceled: true } }"
            class="c-btn--secondary w-full mt-6"
          >
            Back
          </router-link>
        </section>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  InputCheckbox,
  InputDatePicker,
  InputDropdown,
  InputPhone,
  InputState,
  InputText,
  Tooltip,
} from '@mgo/ui/lib/components';
import datePickerMixin from '@mgo/core/src/mixins/datePicker';
import addressViolationsMixin from '../../mixins/addressViolations';
import EditMixin from './mixin';

export default {
  name: 'Edit',
  components: {
    AppLoader,
    InputCheckbox,
    InputDatePicker,
    InputDropdown,
    InputPhone,
    InputState,
    InputText,
    Tooltip,
  },
  mixins: [
    addressViolationsMixin,
    datePickerMixin,
    EditMixin,
  ],
};
</script>
