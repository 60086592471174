<template>
  <Transition
    name="modal"
  >
    <div
      id="multifactor-authentication-modal-backdrop"
      class="modal-backdrop"
      @click="shakeModal"
    >
      <div
        id="multifactor-authentication-modal"
        class="multifactor-authentication-modal"
        :class="{ shake: modalShouldShake }"
      >
        <div
          class="multifactor-authentication-modal-container"
          :class="{'error': activationFailed}"
        >
          <img
            v-if="activationFailed"
            alt=""
            loading="lazy"
            src="@/assets/error-warning.svg"
          >
          <header
            v-else
            class="multifactor-authentication-modal-header"
          >
            Activate Your Change-of-Address Order
          </header>
          <header
            v-if="activationFailed"
            class="multifactor-authentication-modal-header"
          >
            Your Change-of-Address Order is Unsuccessful
          </header>
          <!-- OTP -->
          <div
            v-else
            id="modalDescription"
            class="multifactor-authentication-modal-content"
          >
            To activate your Change-of-Address order, <strong>enter your 8-digit
              activation code</strong> sent to your old mailing address.
          </div>
          <div
            v-if="activationFailed"
            id="modalDescription"
            class="multifactor-authentication-modal-content"
          >
            <p>
              We are unable to complete this Change-of-Address order because there were too many invalid activation code entries.
            </p>
            <br>
            <p>
              <strong>You will need to wait 7 days and start your Change-of-Address from the beginning.</strong>
            </p>
          </div>
          <div
            v-if="activationFailed"
            class="multifactor-authentication-modal-hyperlink"
          >
            <a href="https://www.usps.com/">Go to USPS.com</a>
          </div>
          <!-- OTP -->
          <form
            v-else
            id="multifactor-authentication-form"
            class="multifactor-authentication-modal-form"
            @submit.prevent="validateForm"
          >
            <div class="multifactor-authentication-modal-otp-input-group">
              <label>Activation Code</label>
              <InputText
                v-model="activationPasscode"
                class="multifactor-authentication-modal-otp-input"
                type="text"
                name="activationPasscode"
                autocomplete="activation-code"
                placeholder="00000000"
                enterkeyhint="done"
                inputmode="numeric"
                length="8"
                :has-error="passcodeError.length > 0"
                @input="filterNonNumeric(), passcodeError = ''"
              >
                <span
                  id="activation-error"
                  slot="helper">
                  {{ passcodeError }}
                </span>
              </InputText>
            </div>
            <button
              type="submit"
              class="multifactor-authentication-modal-verify-button"
              :disabled="buttonDisabled"
            >
              Activate
            </button>
          </form>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { InputText } from '@mgo/ui/lib/components';

export default {
  name: 'Activation',
  components: {
    InputText,
  },
  props: {
    activationFailed: {
      type: Boolean,
      default: false,
      required: true,
    },
    uuid: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => ({
    passcodeError: '',
    passcodeException: '',
    activationPasscode: '',
    showMfaModal: false,
    modalShouldShake: false,
    buttonDisabled: false,
  }),
  computed: {
    // computed functions
  },
  async mounted() {
    window.console.log('mounted');
  },
  methods: {
    async startModalShake() {
      this.modalShouldShake = true;
    },
    async shakeModal({ target: { id } }) {
      if (id === 'multifactor-authentication-modal-backdrop') {
        this.startModalShake();
        setTimeout(() => {
          this.modalShouldShake = false;
        }, 1000);
      }
    },
    async validateForm() {
      if (this.activationPasscode.length === 8) {
        this.verifyPasscode();
      } else if (this.activationPasscode.length === 0) {
        this.passcodeError = 'This field cannot be empty.';
      } else {
        this.passcodeError = 'Verification Code must be 8 digits.';
      }
    },
    async verifyPasscode() {
      this.buttonDisabled = true;
      const { passcodeException } = await this.$coac.verifyActivationCode(this);
      if (passcodeException === 'IncorrectActivationCodeException') {
        this.buttonDisabled = false;
        return Object.assign(this, { passcodeException, passcodeError: 'Invalid activation code. Try again.' });
      }
      if (passcodeException === 'ActivationCodeLockedException') {
        return Object.assign(this, { passcodeException, activationFailed: true });
      }

      await this.$coac.sendActivationEmail(this.uuid);
      return this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
    filterNonNumeric() {
      // Replace non-numeric characters with an empty string
      this.activationPasscode = this.activationPasscode.replace(/[^0-9]/g, '');
    },
  },
};
</script>
