<template>
  <div>
    <AppLoader
      v-if="loading"
      modal
    />
    <main v-if="!activationRequired" class="u-container">
      <header>
        <h1
          id="review-heading"
          class="font-bold"
        >
          <!-- EMF, edit and cancel allowed -> 'View, Edit, Cancel or Extend Your Change-of-Address Order' -->
          <!-- Edit and cancel allowed -> 'View, Edit or Cancel Your Change-of-Address Order' -->
          <!-- Just cancel allowed -> 'View or Cancel Your Change-of-Address Order' -->
          <template v-if="flagEmfEnabled && coa.extInfo && coa.extInfo.eligible">
            View, Edit, or Extend Your Change-of-Address Order
          </template>
          <template v-else-if="allowEdit && flagEditable">
            View, Edit or Cancel Your Change-of-Address Order
          </template>
          <template v-else-if="militaryAddress">
            View or Cancel Your Change-of-Address Order
          </template>
          <template v-if="!allowEdit && !militaryAddress">
            View Your Change-of-Address Order
          </template>
        </h1>
      </header>
      <!-- COAC-M manager edit banner - This should be the top most banner -->
      <Banner
        v-if="coa.managerEdit"
        id="banner-manager-edit"
        class="mt-6"
      >
        <span class="font-bold">
          This Change-of-Address order was modified by your local Postal Office due to problems with the old or new address that was originally submitted. Changes to the old or new address following a correction are discouraged as such changes may delay or disrupt the delivery of your mail. Changes to email, phone, forwarding date, type of move or who's moving are encouraged.
        </span>
      </Banner>
      <!-- EMF API error banner -->
      <Banner
        v-if="emfProcessingError"
        id="banner-emf-error"
        class="mt-6"
      >
        <p class="font-bold text-lg text-blue-200">
          Error: Your Extended Mail Forwarding Has NOT Been Updated
        </p>
        <p class="font-bold mt-2">
          An error has occurred. Your mail forwarding extension has not been completed, and you will be issued an automatic refund. Please try again by submitting a new extended mail forwarding request.
        </p>
      </Banner>
      <!-- Success banner -->
      <Banner
        v-if="savedChanges || emfSaved"
        id="banner-success"
        class="mt-6"
      >
        <p
          id="changes-saved"
          class="font-bold text-lg text-blue-200"
        >
          Your Changes Have Been Saved
        </p>
        <!-- Extension success messaging -->
        <p
          v-if="emfSaved && coa.extInfo"
          class="mt-2"
        >
          Your mail forwarding has been updated. Your mail will be forwarded to your new address until {{ dateFormat(coa.extInfo.lastForwardDate, 'MM/dd/yyyy') }}.
        </p>
        <!-- Successful COA save messaging -->
        <p
          v-else
          class="mt-2"
        >
          You will receive a confirmation email shortly. Depending on the original mail forwarding start date of your order, your Official Change-of-Address Confirmation that arrives by mail may not reflect your updated information. If you already received your Official Change-of-Address Confirmation in the mail, then you will not receive another one.
        </p>
      </Banner>
      <Banner
        v-if="savedChanges && coa.medUpdated"
        id="banner-success"
        class="mt-2"
      >
        <p>
          Your mail forwarding start date has been updated to <strong>{{ dateFormat(coa.startDate) }}</strong>
        </p>
      </Banner>
      <!-- EMF advert banner -->
      <Banner
        v-if="showEmfAdvert"
        id="banner-emf-advert"
        class="mt-6"
      >
        <p class="font-bold text-lg text-blue-200">
          Extend Your Mail Forwarding Today
        </p>
        <p class="mt-2">
          By the way, you only get 12 months of free mail forwarding. Make sure your mail continues to be forwarded to your new address beyond the 12 months. Extend your Mail Forwarding End Date now for $19.95 for a 6-month extension, $29.95 for a 12-month extension, or $39.95 for an 18-month extension.
        </p>
        <p class="mt-2">
          Would you like to purchase Extended Mail Forwarding now?
        </p>
        <div class="flex mt-6">
          <router-link
            id="emf-banner-yes"
            :to="{ name: 'extended-mail-forwarding' }"
            class="c-btn c-btn--ghost-blue"
          >
            Yes
          </router-link>
          <button
            id="emf-banner-no"
            type="button"
            class="c-btn ml-6"
            @click="emfAdvertDismissed = true;"
          >
            No
          </button>
        </div>
      </Banner>
      <!-- Old address CMRA banner -->
      <Banner
        v-if="cmraError"
        id="banner-old-address-cmra"
        class="mt-6"
      >
        <p class="font-bold text-lg text-blue-200">
          Your Changes Were NOT Saved
        </p>
        <p
          id="cmra-copy"
          class="mt-2"
        >
          Your edit could not be saved since mail from a commercial mail receiving agency (CMRA) is not forwarded through the USPS. The CMRA customer may make special arrangements for the CMRA operator to re-mail the mail with payment of new postage. A CMRA must accept and re-mail mail to former customers for at least 6 months after termination of the agency relationship. After the 6-month period, the CMRA may refuse mail addressed to a former customer. The Application for Delivery of Mail through Agent (PS Form 1583) requires an addressee and agent to comply with all applicable postal rules and regulations relative to delivery of mail through an agent. For more information on mail forwarding regulations, contact your local Postmaster.
        </p>
      </Banner>
      <!-- COA Processing -->
      <ErrorAlert
        v-if="coa.processing && !activationSuccess"
        id="alert-coa-processing"
        class="mt-4"
      >
        We are unable to modify your Change-of-Address at this time because it is still being processed. Please come back after 72 hours.
      </ErrorAlert>
      <div
        v-if="activationSuccess"
        id="alert-activation-success"
        class="mt-4 activation-success-message"
      >
        <img
          alt=""
          loading="lazy"
          src="@/assets/green-checkmark.svg"
        >
        <span
          id="alert-activation-success-text"
        >
          Your Change-of-Address order has been activated. There is no more action required.
        </span>
      </div>
      <!-- API save error on edit -->
      <ErrorAlert
        v-if="apiException && apiMessage"
        id="alert-api-save-error"
        class="mt-4"
      >
        {{ apiMessage }}
      </ErrorAlert>
      <!-- End of Day EOD -->
      <ErrorAlert
        v-if="coa.eodRunning"
        id="alert-end-of-day"
        class="mt-4"
      >
        Your current order is being processed, please return in 1 hour to modify or cancel it.
      </ErrorAlert>
      <!-- COA Edit Canceled -->
      <ErrorAlert
        v-if="editCanceled"
        id="alert-coa-edit-canceled"
        class="mt-4"
      >
        Your changes were NOT saved.
      </ErrorAlert>
      <!-- AMS Corrections - user will need to use corrected ZIP for future login attempts -->
      <ErrorAlert
        v-if="amsCorrectedNewZip"
        id="alert-ams-corrections-new-zip"
        class="mt-4"
      >
        The New Address ZIP Code™ that you entered has been corrected to the ZIP Code™ found in our address database. Please use the corrected ZIP Code™ for search purposes.
      </ErrorAlert>
      <!-- COA Not canceled -->
      <ErrorAlert
        v-if="coaNotCanceled"
        id="alert-coa-not-canceled"
        class="mt-4"
      >
        Your Change-of-Address has NOT been canceled.
      </ErrorAlert>
      <!-- COA cancel allowed -->
      <p
        v-if="allowCoaCancel"
        class="mt-4"
      >
        Certain elements of your Change-of-Address may not be editable on this system. If you need to change those elements, you will need to cancel this Change-of-Address and submit a new Change-of-Address with the correct information.
      </p>
      <div class="c-hairline mt-6" />
      <div
        v-if="!loading"
        class="mt-6"
      >
        <section>
          <h2 class="font-bold text-2xl">
            Move Information
          </h2>
          <div class="flex mt-6 pb-8">
            <!-- Column 1 -->
            <div class="flex-1">
              <CoaItem label="Processed On">
                {{ dateFormat(coa.processedOnDate, 'LLLL dd, yyyy') }}
              </CoaItem>
              <CoaItem
                :label="coa.moverType === 'BUSINESS' ? 'Business Name': 'Name'"
                class="mt-6"
              >
                <template v-if="coa.moverType != 'BUSINESS'">
                  {{
                    [
                      coa.mover.firstName,
                      coa.mover.middleName,
                      coa.mover.lastName,
                    ].join(' ')
                  }}
                </template>
                <template v-if="coa.moverType === 'BUSINESS'">
                  {{ coa.businessName }}
                </template>
              </CoaItem>
              <CoaItem
                label="Who's Moving"
                class="mt-6"
              >
                {{ coa.moverType }}
              </CoaItem>
              <CoaItem
                label="Email Address"
                class="mt-6"
              >
                {{ coa.mover.email }}
              </CoaItem>
              <CoaItem
                label="Phone Number"
                class="mt-6"
              >
                {{ phoneFormat(coa.mover.homePhone || coa.mover.mobilePhone) }}
              </CoaItem>
            </div>
            <!-- Column 2 -->
            <div class="flex-1">
              <CoaItem label="Type of Move">
                {{ coa.forwardType }}
              </CoaItem>
              <CoaItem
                id="start-date"
                label="Requested Start Forwarding Date"
                class="mt-6"
              >
                {{ dateFormat(coa.startDate) }}
              </CoaItem>
              <CoaItem
                v-if="coa.forwardType === 'TEMPORARY'"
                label="Requested End Forwarding Date"
                class="mt-6"
              >
                {{ dateFormat(coa.endDate) }}
              </CoaItem>
              <CoaItem
                v-if="coa.extInfo.numExtensions"
                label="Additional Months of Mail Forwarding Requested"
                class="mt-6"
              >
                {{ coa.extInfo.numExtensions * 6 }} Months
              </CoaItem>
              <CoaItem
                label="Old Address"
                class="mt-6"
              >
                {{ coa.oldAddress.streetAddress }}
                <br>{{ coa.oldAddress.city }} {{ coa.oldAddress.state }} {{ zip9Format(coa.oldAddress.zip9) }}
                <br v-if="coa.oldAddress.urbanizationName">{{ coa.oldAddress.urbanizationName || '' }}
              </CoaItem>
              <CoaItem
                label="New Address"
                class="mt-6"
              >
                {{ coa.newAddress.streetAddress }}
                <br>{{ coa.newAddress.city }} {{ coa.newAddress.state }} {{ zip9Format(coa.newAddress.zip9) }}
                <br v-if="coa.newAddress.urbanizationName">{{ coa.newAddress.urbanizationName || '' }}
              </CoaItem>
            </div>
            <!-- Column 3 -->
            <div class="flex-1">
              <VoterReg
                v-show="showVoterReg"
                @handoff="handoff"
              />
            </div>
          </div>
        </section>
        <div class="c-hairline mt-6" />
        <div
          v-if="!coaUnEditableAndProcessing"
          class="flex max-w-4xl mt-6"
        >
          <router-link
            v-if="(allowEdit && allowCoaCancel) || militaryAddress"
            id="cancel-coa"
            :to="{ name: 'cancel' }"
            class="c-btn c-btn--secondary"
          >
            Cancel My Order
          </router-link>
          <router-link
            v-if="allowEdit && flagEditable"
            id="edit-order"
            :to="{ name: 'edit' }"
            class="c-btn ml-6"
          >
            Edit My Order
          </router-link>
          <router-link
            v-if="flagEmfEnabled && coa.extInfo.eligible"
            id="extend-mail-forwarding"
            :to="{ name: 'extended-mail-forwarding' }"
            class="c-btn c-btn--secondary ml-6"
          >
            Extend Mail Forwarding
          </router-link>
        </div>
      </div>
    </main>
    <!-- activation modal-->
    <Activation
      v-if="activationRequired"
      :activation-failed="activationFailed"
      :uuid="coa.coaOrderUuid"
      @close="activationRequired = false, refreshCoa()"
    />
  </div>
</template>

<script>
import {
  AppLoader,
  Banner,
  CoaItem,
  ErrorAlert,
} from '@mgo/ui/lib/components';
import VoterReg from '../../components/VoterReg.vue';
import reviewMixin from './mixin';
import Activation from '../Activation/index.vue';

export default {
  name: 'Review',
  components: {
    AppLoader,
    Banner,
    CoaItem,
    ErrorAlert,
    VoterReg,
    Activation,
  },
  mixins: [reviewMixin],
};
</script>
